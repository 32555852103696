import { useEffect, useRef, useState } from 'react'
import TickStyle from '../../components/TickStyle'
import '../../css/pages/services/service1.css'
import image1 from '../../images/service1/1.png'
import image2 from '../../images/service1/2.png'
import image3 from '../../images/service1/3.png'
import image4 from '../../images/service1/4.png'
import { Link } from 'react-router-dom'
import useResponsive from '../../scss/utils/responsive'

const Service1 = () => {
    const refCurative = useRef();
    const refPre = useRef();
    const refPrede = useRef();
    const refOpt = useRef();
    const mainRef = useRef();

    const [textAfterHash, setTextAfterHash] = useState('');

    useEffect(() => {
        const handleUrlChange = () => {
            const url = window.location.href;
            const parts = url.split('#');
            const newTextAfterHash = parts[1] || ''; // Handle the case when there is no '#' in the URL
            setTextAfterHash(newTextAfterHash);
        };

        // Add event listener for URL changes
        window.addEventListener('hashchange', handleUrlChange);

        // Initial call to set the text after the hash on component mount
        handleUrlChange();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('hashchange', handleUrlChange);
        };
    }, []);

    useEffect(() => {
        switch (textAfterHash) {
            case 'curative':
                refCurative.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'preventive':
                refPre.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'predictive':
                refPrede.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'optimisation':
                refOpt.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                window.scrollTo(0, 0);
                break;

        }
    }, [textAfterHash])

    const isMobile = useResponsive()

    return (
        <div className="service1" ref={mainRef}>
            <Link to="/contact">
                <div className='aide'>
                    <div className='content'>
                        <div className="white-round">
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" viewBox="0 0 44 46" fill="none">
                                <path d="M7.11141 0H26.9883C30.8957 0 34.1007 3.19324 34.1007 7.08754V19.7015C34.1007 23.5998 30.8987 26.7901 26.9883 26.7901H18.1243L10.8009 32.3689C10.2054 32.8231 9.36531 32.3225 9.46339 31.5948L9.93497 26.7901H7.11141C3.20301 26.7901 0 23.5978 0 19.7015V7.08754C0 3.19324 3.204 0 7.11141 0ZM13.2668 32.8803C13.2668 32.4192 13.6432 32.044 14.1059 32.044C14.5686 32.044 14.9451 32.4192 14.9451 32.8803V34.006C14.9451 36.6295 17.0949 38.7721 19.7273 38.7721H27.9216C28.1336 38.7721 28.3268 38.8511 28.4744 38.9805L33.6697 42.937L33.3497 39.6865C33.3071 39.2293 33.645 38.8215 34.1037 38.7791L37.5385 38.7721C40.1659 38.7721 42.3217 36.6246 42.3217 34.006V22.7032C42.3217 20.0847 40.1659 17.9371 37.5385 17.9371H36.5141C36.0514 17.9371 35.675 17.5619 35.675 17.1007C35.675 16.6396 36.0514 16.2644 36.5141 16.2644H37.5385C41.0932 16.2644 44 19.1604 44 22.7032V34.006C44 37.5488 41.0932 40.4448 37.5385 40.4448H35.1043L35.5184 44.6521C35.6165 45.3798 34.7764 45.8804 34.1809 45.4262L27.6412 40.4448H19.7273C16.1785 40.4448 13.2668 37.5428 13.2668 34.006V32.8803ZM26.9883 1.67265H7.11141C4.12042 1.67265 1.67829 4.10659 1.67829 7.08754V19.7015C1.67829 22.6776 4.12438 25.1174 7.11141 25.1174L10.9356 25.1243C11.3943 25.1668 11.7321 25.5746 11.6895 26.0318L11.3121 29.8806L17.2911 25.3258C17.4387 25.1964 17.6319 25.1174 17.8439 25.1174H26.9883C29.9763 25.1174 32.4224 22.6815 32.4224 19.7015V7.08754C32.4224 4.1056 29.9793 1.67265 26.9883 1.67265Z" fill="black" />
                            </svg>
                        </div>
                        {!isMobile.lt.md &&
                            <div className='text-content'>
                                <h3> Besoin d'aide ? </h3>
                                <span> N'hésitez pas a nous contacter </span>
                            </div>
                        }
                        
                    </div>
                </div>
            </Link>
            <div className='service-description'>
                <h1>Maintenance et Optimisation d'Installations</h1>
                <span>Maintenance et Optimisation d'Installations - Services sur Mesure</span>
                <p>
                    Découvrez nos services de maintenance professionnelle et d'optimisation d'installations pour garantir la performance et la durabilité
                    de vos équipements. Explorez nos solutions adaptées à vos besoins spécifiques en maintenance
                </p>
                <p>
                    La maintenance et l'optimisation d'installations sont cruciales pour assurer la longévité et les performances optimales de vos équipements.
                    Chez SIANATECH, nous proposons plusieurs types de maintenance pour répondre à vos besoins spécifiques
                </p>
                <p>
                    Chez SIANATECH, notre vaste réseau de fournisseurs fournit des pièces détachées de qualité. En cas d'indisponibilité, notre expertise en
                    modélisation 3D nous permet de créer sur mesure les composants nécessaires. Cette approche assure le maintien efficace de vos équipements,
                    même dans des situations complexes.
                </p>

            </div>

            <div className='style1' ref={refCurative}>
                <div className='card-left'>
                    <h2>Maintenance Curative</h2>
                    <img src={image1} alt='' />
                </div>
                <div className='avantage-description'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <g filter="url(#filter0_d_63_54639)">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#F4F4F4" />
                                <circle cx="10.5" cy="10.5" r="10" stroke="black" />
                            </g>
                            <path d="M6.09668 11.1309L8.68304 13.8706L14.2257 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <defs>
                                <filter id="filter0_d_63_54639" x="0" y="0" width="23" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_63_54639" />
                                    <feOffset dx="1" dy="1" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_54639" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_54639" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <span>La maintenance curative intervient en cas de panne ou de dysfonctionnement. Nos experts interviennent rapidement pour rétablir le bon
                        fonctionnement de vos équipements, minimisant ainsi les temps d'arrêt.
                    </span>
                </div>
            </div>

            <div className='style2' ref={refPre}>
                <div className='avantage-description'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <g filter="url(#filter0_d_63_54639)">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#F4F4F4" />
                                <circle cx="10.5" cy="10.5" r="10" stroke="black" />
                            </g>
                            <path d="M6.09668 11.1309L8.68304 13.8706L14.2257 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <defs>
                                <filter id="filter0_d_63_54639" x="0" y="0" width="23" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_63_54639" />
                                    <feOffset dx="1" dy="1" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_54639" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_54639" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <span>La maintenance préventive consiste à effectuer des interventions planifiées régulières pour prévenir les pannes. Nous réalisons des
                        contrôles périodiques pour identifier et corriger les éventuels problèmes avant qu'ils ne deviennent critiques
                    </span>
                </div>
                <div className='card-right'>
                    <img src={image2} alt='' />
                    <h2>Maintenance Préventive</h2>
                </div>
            </div>

            <div className='style1' ref={refPrede}>
                <div className='card-left'>
                    <h2>Maintenance Prédictive</h2>
                    <img src={image3} alt='' />
                </div>
                <div className='avantage-description'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <g filter="url(#filter0_d_63_54639)">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#F4F4F4" />
                                <circle cx="10.5" cy="10.5" r="10" stroke="black" />
                            </g>
                            <path d="M6.09668 11.1309L8.68304 13.8706L14.2257 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <defs>
                                <filter id="filter0_d_63_54639" x="0" y="0" width="23" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_63_54639" />
                                    <feOffset dx="1" dy="1" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_54639" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_54639" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <span>La maintenance prédictive utilise des données et des analyses pour prédire les pannes potentielles. À l'aide de technologies avancées,
                        nous anticipons les problèmes et agissons proactivement pour éviter les arrêts imprévus.
                    </span>
                </div>
            </div>
            <div className='style2' ref={refOpt}>
                <div className='avantage-description'>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <g filter="url(#filter0_d_63_54639)">
                                <circle cx="10.5" cy="10.5" r="10.5" fill="#F4F4F4" />
                                <circle cx="10.5" cy="10.5" r="10" stroke="black" />
                            </g>
                            <path d="M6.09668 11.1309L8.68304 13.8706L14.2257 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <defs>
                                <filter id="filter0_d_63_54639" x="0" y="0" width="23" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_63_54639" />
                                    <feOffset dx="1" dy="1" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_54639" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_54639" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <span>En plus de la maintenance, nous proposons des solutions d'optimisation pour maximiser les performances de vos installations.
                        Nous identifions les améliorations potentielles et mettons en place des stratégies pour augmenter l'efficacité opérationnelle
                        de vos équipements.
                    </span>
                </div>
                <div className='card-right'>
                    <img src={image4} alt='' />
                    <h2>Optimisation d'Installations</h2>
                </div>
            </div>

            <div className='outro'>
                <div className='outro-text'>
                    <h3> CHEZ SIANATECH </h3>
                    <span> Notre approche proactive et nos solutions sur mesure garantissent la fiabilité et l'efficacité à long terme de vos installations. </span>
                </div>
                <div className='outro-style'>
                    <div className='services'>
                        <TickStyle text="Suivi régulier assurant le bon fonctionnement des équipements" serviceStyle='service' id='service1' />
                        <TickStyle text="Prolongation de la durée de vie des installations, réduisant les temps d'arrêt." serviceStyle='service' />
                        <TickStyle text="Propositions concrètes pour optimiser les performances et l'efficacité" serviceStyle='service' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service1;